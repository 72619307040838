import React, { useRef } from 'react'
import { Fade } from "react-reveal"
//import logoImage from '../assets/images/rushlogo-blue.png'
//import logoImage from '../assets/images/rush-logo-white.png'
import LogoCentered from '../components/LogoCentered'
//import ReactTooltip from 'react-tooltip'
import { Link } from 'gatsby'
//import Flippy, { FrontSide, BackSide } from 'react-flippy'
import '../assets/css/fontawesome-all.min.css'

const logoStyle = {
	//marginLeft: '-40px',
	left: '-30px',
	position: 'relative',
}

//const Four = props => (
function Services( {...props} ) {
	const ref = useRef()
	return (
  <Fade up>
    <section id="services" className="wrapper style1 special fade-up">
      <div className="container">
        <header className="major">
				{/*<LogoCentered />*/}
				<span style={logoStyle}><LogoCentered /></span>
		{/*<img style={logoStyle} alt="Rush logo blue" width="200" src={logoImage} />*/}
          <h2>Μπορούμε να συζητήσουμε για τις ακόλουθες υπηρεσίες</h2>
          <p>
            Καλέστε μας τώρα στο <a href="tel:2103310240">210-3310240</a>
          </p>
        </header>
        <div className="box alt">
          <div className="row gtr-uniform">
            <section className="col-4 col-6-medium col-12-xsmall">
						{/*<Flippy
							flipOnHover={false} // default false
							flipOnClick={true} // default false
							flipDirection="horizontal" // horizontal or vertical
							ref={ref} // to use toggle method like ref.curret.toggle()
							// if you pass isFlipped prop component will be controlled component.
							// and other props, which will go to div
							style={{ height: '360px' }} /// these are optional style, it is not necessary
						>
						<FrontSide>*/}
			{/*<p data-tip="<div style='max-width: 150px;' >Προσωπική ιστοσελίδα,Εταιρική παρουσίαση, Υπηρεσίες, Portfolio, Blog" data-type="error" data-html="true" data-for="websites">*/}
			<span className="icon solid alt major fa-columns"></span>
			{/*<ReactTooltip id="websites" effect="float" place="right" />*/}
              <h3>Κατασκευή Ιστοσελίδας</h3>
              <p>
                Προσωπική ιστοσελίδα, Εταιρική παρουσίαση, Υπηρεσίες, Portfolio, Blog
              </p>
							{/*</FrontSide>
							<BackSide style={{ color: '#0d325a', backgroundColor: '#8bbddf'}}>
					      Rush
					    </BackSide>
						</Flippy>*/}
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
						{/*<Flippy
							flipOnHover={false} // default false
							flipOnClick={true} // default false
							flipDirection="horizontal" // horizontal or vertical
							ref={ref} // to use toggle method like ref.curret.toggle()
							style={{ height: '360px' }}
						>
						<FrontSide>*/}
			{/*<p data-tip="Δημιουργήστε το ηλεκτρονικό σας κατάστημα και μπείτε στην νέα εποχή ηλεκτρονικού εμπορίου">*/}
			<span className="icon solid alt major fa-shopping-cart"></span>
			  {/*<ReactTooltip place="right" />*/}
              <h3>E-shop</h3>
              <p>
                Δημιουργήστε το ηλεκτρονικό σας κατάστημα και μπείτε στην νέα εποχή ηλεκτρονικού εμπορίου
              </p>
							{/*</FrontSide>
							<BackSide style={{ color: '#0d325a', backgroundColor: '#8bbddf'}}>
					      Rush
					    </BackSide>
						</Flippy>*/}
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
						{/*<Flippy
							flipOnHover={false} // default false
							flipOnClick={true} // default false
							flipDirection="horizontal" // horizontal or vertical
							ref={ref} // to use toggle method like ref.curret.toggle()
							style={{ height: '360px' }}
						>
						<FrontSide>*/}
              <span className="icon solid alt major fa-tablet-alt"></span>
              <h3>Mobile apps - Web applications</h3>
              <p>
                Εφαρμογές b2b, custom εφαρμογές, αυτοματισμοί
              </p>
							{/*</FrontSide>
							<BackSide style={{ color: '#0d325a', backgroundColor: '#8bbddf'}}>
					      Rush
					    </BackSide>
						</Flippy>*/}
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-comment"></span>
              <h3>Συμβουλευτική</h3>
              <p>
                Σύμφωνα με την πολυετή εμπειρία μας, θα βρούμε το ποιο αποδοτικό σενάριο για το project σας
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-bullseye"></span>
              <h3>Online marketing</h3>
              <p>
                Προώθηση, διαφήμιση, social media
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fab brands fa-connectdevelop "></span>
              <h3>Σύνδεση με ERP, CRM</h3>
              <p>
                Αυτοματισμοί μεταξύ μηχανογράφησης - λογιστικής και ιστοσελίδας.
              </p>
            </section>
          </div>
        </div>
        <footer className="major">
          <ul className="actions special">
            <li>
              <Link to="/contact" className="button">
                Επικοινωνήστε τώρα
              </Link>
            </li>
          </ul>
        </footer>
      </div>
    </section>
  </Fade>
 )
}

export default Services
