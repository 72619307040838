//import React, { useState, useEffect} from 'react'
import React from 'react'
//import { useState, useEffect} from 'preact/hooks'
//import { h, FunctionalComponent } from 'preact';
//import { Helmet } from 'react-helmet'
import Seo from '../components/SEO'
import metaImage from '../assets/images/rush-promo.jpg'
import Layout from '../components/layout'
//import Contact from '../components/Contact'
//import pic07 from '../assets/images/pic07.jpg'
//import pic06 from '../assets/images/pic06.jpg'
import  Animated  from '../components/animated';
//import { useScroll } from '../hooks/useScrolling'
import Services from '../components/Four'
import Shapes from '../components/Shapes'
import loadable from '@loadable/component'
const Lottie1 = loadable(() => import('../components/lottieServices'))

//const scroll = useScroll()
//console.log(scroll);
/*const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = useState(null)
    const [prevOffset, setPrevOffset] = useState(0)
    const toggleScrollDirection = () => {
       let scrollY = window.scrollY
       if (scrollY === 0) {
           setScrollDirection(null)
       }
       if (scrollY > prevOffset) {
           setScrollDirection("down")
       } else if (scrollY < prevOffset) {
           setScrollDirection("up")
       }
       setPrevOffset(scrollY)
    }
    useEffect(() => {
        window.addEventListener("scroll", toggleScrollDirection)
        return () => {
            window.removeEventListener("scroll", toggleScrollDirection)
        }
    })
    return scrollDirection
}*/

//const scrollDirection = useScrollDirection()
//console.log(scrollDirection)

const ServicesPage = props => (
  <Layout>
    {/*<Helmet>
      <title>Υπηρεσίες - Ιστοσελίδες, eshop, εφαρμογές, συνδέσεις Rush</title>
      <meta name="description" content="Ιστοσελίδες, eshop, εφαρμογές web & mobile, αυτοματισμοί, συνδέσεις με erp, crm. Καλέστε μας για να ξεκινήσουμε " />
	  <meta name="google-site-verification" content="Hx8Ona9t42pvK6oaHKBr3C5clqrFclYEE-zXFxc7YnI" />
    </Helmet>*/}
		<Seo
			title='Υπηρεσίες - Ιστοσελίδες, eshop, εφαρμογές, συνδέσεις - Rush speedy web apps'
			description='Ιστοσελίδες, eshop, εφαρμογές web & mobile, αυτοματισμοί, συνδέσεις με erp, crm. Καλέστε μας για να ξεκινήσουμε'
			//image="/assets/image/rush-promo.jpg"
			image={metaImage}
			pathname="/services"
		//	website
		/>

    <div id="main" className="wrapper style1 ">
	<Shapes />
      <div className="container">
        <header className="major">
		<div className="row gtr-150">
		<div className="col-12">
          <h1><Animated>
		  Υπηρεσίες Rush
		  </Animated>
		  </h1>
		  </div>
		<div className="col-12">
			<Lottie1 />
		  </div>
		  </div>
          {/*<p>
			Καλέστε μας στο 6977000000 ή συμπληρώστε την παρακάτω φόρμα επικοινωνίας και σύντομα θα επικοινωνήσουμε μαζί σας.
          </p>*/}
        </header>
        <div className="row gtr-150">
          <div className="col-12">
            <section id="content">
{/*<Contact />*/}
<Services />
            </section>
          </div>

        </div>
      </div>
    </div>
  </Layout>
)

export default ServicesPage
