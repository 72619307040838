import React from "react"
import { StaticImage } from "gatsby-plugin-image"

function Logo() {

  return (
    <StaticImage
    style={{margin: '20px auto'}}
      src="../assets/images/rush-logo-white.png"
      alt="Rush logo"
      placeholder="blurred"
      layout="fixed"
      width={200}
      //height={55}
    />
  )
}
export default Logo
